import "./App.css";

import { HashRouter, Route, Routes } from 'react-router-dom';
import Support from "./Support";

const Home = () => {
  return (
    <div className="container">
      <div className="bg-image"/>
      <div className="content">
        <h1 className="title">Automatique Inc</h1>
        <p
          style={{
            marginBottom: 32,
          }}
          className="description"
        >
          Building Artificial Inteligence (AI) products.
        </p>
        <a href="/#/support" className="button">
          Contact Us
        </a>
      </div>
    </div>
  )
}

function App() {
  return (
  <HashRouter>
    <Routes>
    <Route
        path='/'
        Component={Home}
      />
      <Route
        path='support'
        Component={Support}
      />
    </Routes>
  </HashRouter>
  );
}

export default App;
