import "./App.css";

function Support() {
  return (
    <div className="container">
      <div className="bg-image"/>
      <div className="content">
        <h1 className="title">Automatique Inc</h1>
        <p className="description">
          For support on Pablo you can reach out directly via email:{" "}
          <a
            style={{
              textDecoration: "underline",
            }}
            href="mailto:beau@fakers.place"
          >
            beau@fakers.place
          </a>
        </p>
        <p
          className="description"
          style={{
            marginBottom: 32,
          }}
        >
          You can also get support by joining our Discord community and
          messaging in the{" "}
          <a
            style={{
              color: "white",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              // padding: 1,
              borderRadius: 4,
            }}
          >
            #support
          </a>{" "}
          channel here{" "}
          <a
            style={{
              textDecoration: "underline",
            }}
            href="https://discord.gg/WqYQhkKgVh"
          >
            Discord
          </a>
        </p>
        <a
          href="/"
          style={{
            textDecoration: "none",
          }}
          className="button"
        >
          Back to home
        </a>
      </div>
    </div>
  );
}

export default Support;
